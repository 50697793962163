import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { Line } from 'react-chartjs-2';
import { useBluetoothContext } from '../store/context/BluetoothProvider'; // Adjust the import path as needed
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

// Register the components
ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend
);

const HomePage = () => {
  const { data, getDataFromDevice } = useBluetoothContext();

  // Example data for the chart
  const chartData = {
    labels: data.map((entry, index) => `Entry ${index + 1}`),
    datasets: [
      {
        label: 'Battery Level',
        data: data.map(entry => entry.batteryLevel),
        fill: false,
        backgroundColor: '#0077cc',
        borderColor: '#0077cc',
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Stats
      </Typography>
      <Box sx={{ height: 300 }}>
        <Line data={chartData} options={chartOptions} />
      </Box>
      <Button 
        variant="contained" 
        color="primary" 
        onClick={getDataFromDevice} 
        sx={{ mt: 2 }}
      >
        Refresh Data
      </Button>
    </Box>
  );
};

export default HomePage;
