import React from 'react';
import { BottomNavigation, BottomNavigationAction, Box } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import HistoryIcon from '@mui/icons-material/History';
import SettingsIcon from '@mui/icons-material/Settings';
import HomePage from './views/HomePage';
import HistoryPage from './views/HistoryPage';
import SettingsPage from './views/SettingsPage';



import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';


const App = () => {
  const [value, setValue] = React.useState(0);

  const renderPage = () => {
    switch (value) {
      case 0:
        return <HomePage />;
      case 1:
        return <HistoryPage />;
      case 2:
        return <SettingsPage />;
      default:
        return <HomePage />;
    }
  };

  return (
    <Box sx={{ pb: 7, mt:"56px"}}>
      <AppBar position="fixed">
        <Toolbar>
          <img src='logo.png' style={{height:"30px"}}/>
          <Typography variant="h6" component="div" sx={{ ml:2, flexGrow: 1 }}>
            Idrate
          </Typography>
          <Button color="inherit">Connect</Button>
        </Toolbar>
      </AppBar>



      {renderPage()}
      <BottomNavigation
        value={value}
        onChange={(event, newValue) => setValue(newValue)}
        showLabels
        sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
      >
        <BottomNavigationAction label="Stats" icon={<HomeIcon />} />
        <BottomNavigationAction label="History" icon={<HistoryIcon />} />
        <BottomNavigationAction label="Settings" icon={<SettingsIcon />} />
      </BottomNavigation>
    </Box>
  );
};

export default App;
