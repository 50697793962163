import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BluetoothProvider } from './store/context/BluetoothProvider';
import { CssBaseline } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#0077cc',
    },
    background: {
      default: '#fff',
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
        <BluetoothProvider>
          <App />
        </BluetoothProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
