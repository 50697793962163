import React, { useState } from 'react';
import { Box, Typography, TextField, Button, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton } from '@mui/material';
import { useBluetoothContext } from '../store/context/BluetoothProvider';
import BluetoothIcon from '@mui/icons-material/Bluetooth';

const SettingsPage = () => {
  const { device, requestDevice, connectToPairedDevice } = useBluetoothContext();
  const [deviceName, setDeviceName] = useState(device ? device.name : '');


  const handleDiscoverDevices = async () => {
    console.log('Discovering devices');
    try {
      const devices = await navigator.bluetooth.requestDevice({ acceptAllDevices: true });
      console.log('Discovered devices:', devices);
      setDeviceName(devices.name);
    } catch (error) {
      console.error('Error discovering devices:', error);
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Settings Page
      </Typography>
      {/*<Box sx={{ mb: 3 }}>
        <TextField
          label="Connected Device Name"
          value={deviceName}
          variant="outlined"
          fullWidth
          disabled
          sx={{ mb: 2 }}
        />
        <Button variant="contained" color="primary" onClick={requestDevice} sx={{ mb: 2 }}>
          Connect to Device
        </Button>
        <Button variant="contained" color="primary" onClick={handleDiscoverDevices} sx={{ mb: 2 }}>
          Discover Devices
        </Button>
      </Box>
      <Typography variant="h6" gutterBottom>
        Paired Devices
      </Typography>
      <List>
        
      </List>
      <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
        Stored Data
      </Typography>
      <List>
  </List>*/}
    </Box>
  );
};

export default SettingsPage;
