import React, { createContext, useState, useEffect, useContext } from 'react';

const BluetoothContext = createContext();

export const useBluetoothContext = () => {
  return useContext(BluetoothContext);
};

export const BluetoothProvider = ({ children }) => {
  const [device, setDevice] = useState(null);
  const [data, setData] = useState([]);

  const connectToDevice = async (device) => {
    console.log('Connecting to device:', device);
    try {
      const server = await device.gatt.connect();
      const service = await server.getPrimaryService('battery_service'); // Example service
      const characteristic = await service.getCharacteristic('battery_level'); // Example characteristic
      const value = await characteristic.readValue();
      const batteryLevel = value.getUint8(0);
      console.log('Battery level:', batteryLevel);
      setData((prevData) => [...prevData, { batteryLevel }]);
    } catch (error) {
      console.error('Error in connecting to device:', error);
    }
  };

  const getDataFromDevice = async (device) => {
    console.log('Getting data from device:', device);
    try {
      const server = await device.gatt.connect();
      const service = await server.getPrimaryService('battery_service'); // Example service
      const characteristic = await service.getCharacteristic('battery_level'); // Example characteristic
      const value = await characteristic.readValue();
      const batteryLevel = value.getUint8(0);
      console.log('Battery level:', batteryLevel);
      setData((prevData) => [...prevData, { batteryLevel }]);
    } catch (error) {
      console.error('Error in getting data from device:', error);
    }
  };

  const requestDevice = async () => {
    console.log('Requesting device...');
    try {
      const device = await navigator.bluetooth.requestDevice({ acceptAllDevices: true });
      console.log('Paired device:', device);
      setDevice(device);
      await connectToDevice(device);
    } catch (error) {
      console.error('Error requesting device:', error);
    }
  };

  const connectToPairedDevice = async (deviceInfo) => {
    console.log('Connecting to paired device:', deviceInfo);
    try {
      const device = await navigator.bluetooth.requestDevice({
        filters: [{ name: deviceInfo.name }]
      });
      setDevice(device);
      await connectToDevice(device);
    } catch (error) {
      console.error('Error connecting to paired device:', error);
    }
  };

  return (
    <BluetoothContext.Provider value={{ device, data, requestDevice, connectToDevice, getDataFromDevice, connectToPairedDevice }}>
      {children}
    </BluetoothContext.Provider>
  );
};
